* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #f7f7f7;
  text-align: center;
}