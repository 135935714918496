@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
.randomizer {
    width: 500px;
    overflow: hidden;
    max-height: 470px;
    padding: 12px 32px;
    border-radius: 40px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.randomizer h1 {
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-size: xx-large;
    margin-bottom: 10px;
}

.randomizer p {
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    margin-bottom: 10px;
}

.randomizer button {
    margin-top: 20px;
    outline: none;
    border: 1px solid black;
    padding: 12px 32px;
    border-radius: 40px;
    background-color: none;
    color: black;
    font-family: "Work Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    cursor: pointer;
    transition: .3s linear;
}

.randomizer button :hover {
    background: black;
    color: #fff;
}